import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import styled, { css } from "styled-components"
import { media } from "../styles/media"

// import corpLogo from "images/logo-sample.png"

// export const query = graphql`
//   {}
// `

const StyledWrapper = styled.div`
  width: 100%;
  height: 44px;
  ${() =>
    media.pc(css`
      height: 80px;
    `)}
  position:absolute;
  top: 0;
  left: 0;
  z-index: 99;
  header {
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    .gatsby-image-wrapper {
      width: 80%;
      ${() =>
        media.pc(css`
          max-width: 400px;
          box-shadow: 0px 0px 15px gray;
        `)}
    }
  }
`

const Header = () => {
  return (
    <StyledWrapper>
      <header>
        <StaticImage
          src="../images/logologo.png"
          placeholder="none"
          alt="日本カラープランニング協会"
        />
      </header>
    </StyledWrapper>
  )
}

export default Header
