/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
// import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

// function SEO({ description, lang, meta, title, children }) {
function SEO({ description, lang, meta, pageUrl, title, jsonld = null }) {
  const data = useStaticQuery(query)
  const defaultTitle = data.text.siteMetadata.title

  // Default Website Schema
  let schemaOrgJSONLD = [
    {
      "@context": "http://schema.org",
      "@type": "WebSite",
      url: data.text.siteMetadata.siteUrl,
      name: data.text.siteMetadata.title,
      // alternateName: headline ? headline : "",
    },
  ]
  if (jsonld) {
    // add to default schemaOrgJSONLD variable
    // const addedSchemaOrgJSONLD = [
    //   {
    //     "@context": "http://schema.org",
    //     "@type": "WebSite",
    //     url: site.siteUrl,
    //     name: defaultTitle,
    //   },
    // ]
    if (Array.isArray(jsonld)) {
      schemaOrgJSONLD = [...schemaOrgJSONLD, ...jsonld]
    } else if (typeof jsonld === "object") {
      schemaOrgJSONLD.push(jsonld)
    }
  }

  return (
    <Helmet>
      <html lang="ja" />
      <title>{defaultTitle}</title>
      <meta name="description" content={data.text.siteMetadata.description} />
      <meta name="image" content={data.text.siteMetadata.image || ""} />
      <meta
        name="apple-mobile-web-app-title"
        content={data.text.siteMetadata.title}
      />
      <meta name="application-name" content={data.text.siteMetadata.title} />
      <script type="application/ld+json">
        {JSON.stringify(schemaOrgJSONLD)}
      </script>

      {/* {children} */}

      {/* OpenGraph  */}
      {/* <meta property="og:title" content="<SOME TEXT HERE>" />
      <meta property="og:site_name" content="<SOME TEXT HERE>" />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={process.env.SITE_URL} />
      <meta property="og:description" content="" />
      <meta
        property="og:image"
        content={`${process.env.SITE_URL}/images/gatsby-icon.png`}
      />
      <meta property="fb:app_id" content="<FACEBOOK ID>" /> */}

      {/* Twitter Card */}
      {/* <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content={twitter} />
      <meta name="twitter:title" content={seo.title} />
      <meta name="twitter:description" content={seo.description} />
      <meta name="twitter:image" content={seo.image} /> */}
    </Helmet>
  )
}

// SEO.defaultProps = {
//   lang: `ja`,
//   title: "",
//   meta: [],
//   description: ``,
// }

// SEO.propTypes = {
//   description: PropTypes.string,
//   lang: PropTypes.string,
//   meta: PropTypes.arrayOf(PropTypes.object),
//   title: PropTypes.string.isRequired,
// }

export const query = graphql`
  {
    text: dataYaml {
      siteMetadata {
        description
        title
        siteUrl
      }
    }
  }
`

export default SEO
