import React from "react"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"

const Foot = styled.div`
  background: #292929;
  padding: 40px 20px;
`
const S1st = styled.div`
  a {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    width: 100%;
    .gatsby-image-wrapper {
      width: 300px;
      height: auto;
      object-fit: cover;
    }
  }
`

const Footer = () => {
  return (
    <Foot>
      <S1st>
        <a
          href="https://color-planning.com"
          rel="noopener noreferrer"
          target="_blank"
        >
          <StaticImage
            src="../images/logologo.png"
            placeholder="none"
            alt="日本カラープランニング協会のロゴ"
          />
        </a>
      </S1st>
    </Foot>
  )
}

export default Footer
