// import libraries
import React from "react"
import styled, { ThemeProvider } from "styled-components"

// import styles and custom react hooks
import theme from "../styles/theme"
import GlobalStyle from "../styles/globalStyle"
import { HeaderOverWrappedProvider } from "../hooks/HeaderOverWrappedContext"

// import components
import Header from "../components/Header"
import Footer from "../components/Footer"

const StyledLayoutWrapper = styled.div`
  width: 100%;
  overflow: hidden;
  font-size: 16px;
  color: #292929;
`

const Layout = ({ children }) => {
  return (
    <StyledLayoutWrapper>
      <GlobalStyle />
      <ThemeProvider theme={theme}>
        <HeaderOverWrappedProvider>
          <Header />
          <main>{children}</main>
          <Footer />
        </HeaderOverWrappedProvider>
      </ThemeProvider>
    </StyledLayoutWrapper>
  )
}

export default Layout
