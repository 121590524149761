const theme = {
  colors: {
    primary: {
      black: `#292929`,
      pink: `#E3007F`,
      yellow: `#F29600`,
      green: `#009844`,
      blue: `#0076DE`,
      gray: `#E5E5E5`,
    },
    light: {
      brown: `#E4D8B9`,
    },
    dark: {
      gray: `#C4C4C4`,
    },
  },
}

export default theme
